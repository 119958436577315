<template>
  <DashboardSection>
    <template #top-right-button>
      <CustomButton
        @onClick="$router.push({ name: 'EditStudioDetails' })"
        iconClass="edit"
        label="Edit details"
        color="grey"
        data-testid="editDetailButton"
      />
    </template>
    <template #content>
      <div class="board" v-if="studioDetails">
        <div class="board__section">
          <span class="board__section-heading">Studio name</span>
          <p>{{ studioDetails.name }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Company number</span>
          <p>{{ studioDetails.legalId }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Registered address</span>
          <p>{{ studioDetails.address }}</p>
        </div>

        <div class="board__section">
          <span class="board__section-heading">Mailing address</span>
          <p>{{ studioDetails.mailAddress ? studioDetails.mailAddress : 'NA' }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Incorporated on</span>
          <p>{{ studioDetails.incorporatedOn ? filterDate(studioDetails.incorporatedOn) : ' -' }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Standing work hour per day</span>
          <p>{{ studioDetails.standardWorkHours }}h</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Working days per year</span>
          <p>{{ studioDetails.workingDaysPerYear }}</p>
        </div>
        <div class="board__section" v-if="studioDetails.studioServices.length > 0">
          <span class="board__section-heading">Services</span>
          <p v-for="service in studioDetails.studioServices" :key="service.id">
            {{ service.name.replace('_', ' ') }}
          </p>
        </div>
      </div>
      <h3 v-if="studioDetails && studioDetails.accountNumber">Financial details</h3>
      <div class="board" v-if="studioDetails">
        <div class="board__section" v-if="studioDetails.accountNumber">
          <span class="board__section-heading">Account number</span>
          <p>{{ studioDetails.accountNumber }}</p>
        </div>
        <div class="board__section" v-if="studioDetails.bankName">
          <span class="board__section-heading">Bank name</span>
          <p>{{ studioDetails.bankName }}</p>
        </div>
        <div class="board__section" v-if="studioDetails.bankAddress">
          <span class="board__section-heading">Bank address</span>
          <p>{{ studioDetails.bankAddress }}</p>
        </div>

        <div class="board__section" v-if="studioDetails.sortCode">
          <span class="board__section-heading">Sort Code / BSB number</span>
          <p>{{ studioDetails.sortCode }}</p>
        </div>
        <div class="board__section" v-if="studioDetails.iban">
          <span class="board__section-heading">IBAN</span>
          <p>{{ studioDetails.iban }}</p>
        </div>
        <div class="board__section" v-if="studioDetails.bic">
          <span class="board__section-heading">BIC</span>
          <p>{{ studioDetails.bic }}</p>
        </div>
        <div class="board__section" v-if="studioDetails.vatNumber">
          <span class="board__section-heading">VAT number</span>
          <p>{{ studioDetails.vatNumber }}</p>
        </div>
        <div class="board__section" v-if="studioDetails.VatPercentage">
          <span class="board__section-heading">VAT / GST percentage</span>
          <p>{{ studioDetails.VatPercentage }}%</p>
        </div>
      </div>
    </template>
  </DashboardSection>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import authApi from '@/api/Authenticated'
import moment from 'moment'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import type { studioTypes } from '@shared/'
export default defineComponent({
  name: 'Details',
  components: {
    DashboardSection,
    CustomButton
  },
  setup() {
    const studioDetails = ref<studioTypes.StudioResponse>()

    onMounted(async () => {
      studioDetails.value = await authApi.getStudioDetails()
    })

    function filterDate(date: Date) {
      return moment(date).format('DD/MM/YYYY')
    }
    return { studioDetails, filterDate }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.board
  display: flex
  flex-wrap: wrap
  & > *
    margin: 1rem
    max-width: 18rem
    min-height: 7rem
    @media( max-width: $md-breakpoint)
      width: 100%
      max-width: unset
  &__section
    display: inline-block
    width: 100%
    background-color: $white
    padding: 1rem
    border-radius: 4px
    word-break: break-all
    @media( max-width: $ms-breakpoint)
      width: 100%
  &__section-heading
    font-weight: 700
</style>
