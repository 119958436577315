
import { defineComponent, onMounted, ref } from 'vue'
import authApi from '@/api/Authenticated'
import moment from 'moment'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import type { studioTypes } from '@shared/'
export default defineComponent({
  name: 'Details',
  components: {
    DashboardSection,
    CustomButton
  },
  setup() {
    const studioDetails = ref<studioTypes.StudioResponse>()

    onMounted(async () => {
      studioDetails.value = await authApi.getStudioDetails()
    })

    function filterDate(date: Date) {
      return moment(date).format('DD/MM/YYYY')
    }
    return { studioDetails, filterDate }
  }
})
